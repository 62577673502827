import {dateToString} from "@/helpers";

export default {
	name: "TimestampsMixin",

	computed: {
		created_at: {
			get() {
				return this.$_.get(this[this.mainVarName], 'created_at')
					? dateToString(this[this.mainVarName].created_at.toDate())
					: '';
			},
			set() {
				//não faz nada
			}
		},

		updated_at: {
			get() {
				return this.$_.get(this[this.mainVarName], 'updated_at')
					? dateToString(this[this.mainVarName].updated_at.toDate())
					: '';
			},
			set() {
				//não faz nada
			}
		}
	},
}
