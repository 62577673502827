<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button icon="chevron-back-outline"></ion-back-button>
                </ion-buttons>
                <ion-title>{{ $route.meta.title }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div v-if="!carregandoUsuario">
                <ion-item>
                    <ion-label position="stacked">Código</ion-label>
                    <ion-input v-model="user.code"></ion-input>
                </ion-item>

                <ion-item :class="{'ion-invalid': v$.user.name.$invalid }">
                    <ion-label position="stacked">Nome / Descrição</ion-label>
                    <ion-input v-model="user.name" v-uppercase></ion-input>
                </ion-item>
                <validation-error :field="v$.user.name"></validation-error>

                <ion-item :class="{'ion-invalid': v$.user.email.$invalid }">
                    <ion-label position="stacked">E-mail(s) - Separe os endereços com ; (ponto e vírgula)</ion-label>
                    <ion-input v-model="user.email" type="email" v-lowercase></ion-input>
                </ion-item>
                <validation-error :field="v$.user.email"></validation-error>

                <div v-if="operation === 'create'">
                    <ion-item :class="{'ion-invalid': v$.user.password.$invalid }">
                        <ion-label position="stacked">Senha</ion-label>
                        <ion-input v-model="user.password" type="password" v-lowercase></ion-input>
                        <validation-error :field="v$.user.password"></validation-error>
                    </ion-item>
                </div>
                <div v-else>
                    <ion-item>
                        <ion-label class="ion-text-wrap">Editar senha?</ion-label>
                        <custom-toggle @click="changePassword = !changePassword" :checked="changePassword" :key="changePassword" color="primary"></custom-toggle>
                    </ion-item>

                    <ion-item :class="{'ion-invalid': v$.newPassword.$invalid }" v-if="changePassword">
                        <ion-label position="stacked">Nova Senha</ion-label>
                        <ion-input v-model="newPassword" type="password"></ion-input>
                        <validation-error :field="v$.newPassword"></validation-error>
                    </ion-item>
                </div>

                <ion-item :class="{'ion-invalid': v$.user.status.$invalid }">
                    <ion-label position="floating">Status</ion-label>
                    <custom-select v-model="user.status">
                        <ion-select-option value="active">Ativo</ion-select-option>
                        <ion-select-option value="inactive">Inativo</ion-select-option>
                    </custom-select>
                </ion-item>
                <validation-error :field="v$.user.status"></validation-error>

                <ion-item :class="{'ion-invalid': v$.user.source.$invalid }">
                    <ion-label position="stacked">Estab. de Origem Padrão</ion-label>
                    <custom-select v-model="user.source">
                        <ion-select-option value="Pardinho">Pardinho</ion-select-option>
                        <ion-select-option value="Santa Cruz">Santa Cruz</ion-select-option>
                    </custom-select>
                </ion-item>
                <validation-error :field="v$.user.source"></validation-error>

                <ion-list><!-- :key="fix" -->
                    <ion-list-header>
                        <ion-label>Permissões</ion-label>
                    </ion-list-header>

                    <ion-item-group>
                        <ion-item-divider>
                            <ion-label>Clientes</ion-label>
                        </ion-item-divider>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Sincronizar</ion-label>
                            <custom-toggle @change="atualizaPermissao('sync_customers', $event)" :checked="$_.get(user.permissions,'sync_customers')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Visualizar qualquer cliente</ion-label>
                            <custom-toggle @change="atualizaPermissao('view_anyone_customer', $event)" :checked="$_.get(user.permissions,'view_anyone_customer')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Visualizar seus clientes</ion-label>
                            <custom-toggle @change="atualizaPermissao('view_yourself_customers', $event)" :checked="$_.get(user.permissions,'view_yourself_customers')" color="primary"></custom-toggle>
                        </ion-item>
                    </ion-item-group>

                    <ion-item-group>
                        <ion-item-divider>
                            <ion-label>Solicitações</ion-label>
                        </ion-item-divider>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Criar solicitações para qualquer um</ion-label>
                            <custom-toggle @change="atualizaPermissao('create_requests_for_anyone', $event)" :checked="$_.get(user.permissions,'create_requests_for_anyone')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Criar solicitações para si mesmo</ion-label>
                            <custom-toggle @change="atualizaPermissao('create_requests_for_yourself', $event)" :checked="$_.get(user.permissions,'create_requests_for_yourself')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Deletar solicitações para qualquer um</ion-label>
                            <custom-toggle @change="atualizaPermissao('delete_requests_for_anyone', $event)" :checked="$_.get(user.permissions,'delete_requests_for_anyone')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Deletar solicitações para si mesmo</ion-label>
                            <custom-toggle @change="atualizaPermissao('delete_requests_for_yourself', $event)" :checked="$_.get(user.permissions,'delete_requests_for_yourself')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Editar solicitações para qualquer um</ion-label>
                            <custom-toggle @change="atualizaPermissao('update_requests_for_anyone', $event)" :checked="$_.get(user.permissions,'update_requests_for_anyone')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Editar solicitações para si mesmo</ion-label>
                            <custom-toggle @change="atualizaPermissao('update_requests_for_yourself', $event)" :checked="$_.get(user.permissions,'update_requests_for_yourself')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Visualizar solicitações de qualquer um</ion-label>
                            <custom-toggle @change="atualizaPermissao('view_requests_for_anyone', $event)" :checked="$_.get(user.permissions, 'view_requests_for_anyone')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Visualizar solicitações de si mesmo</ion-label>
                            <custom-toggle @change="atualizaPermissao('view_requests_for_yourself', $event)" :checked="$_.get(user.permissions,'view_requests_for_yourself')" color="primary"></custom-toggle>
                        </ion-item>
                    </ion-item-group>

                    <br>

                    <ion-item-group>
                        <ion-item-divider>
                            <ion-label>Usuários</ion-label>
                        </ion-item-divider>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Criar usuários</ion-label>
                            <custom-toggle @change="atualizaPermissao('create_users', $event)" :checked="$_.get(user.permissions,'create_users')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Deletar usuários</ion-label>
                            <custom-toggle @change="atualizaPermissao('delete_users', $event)" :checked="$_.get(user.permissions,'delete_users')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Editar usuários</ion-label>
                            <custom-toggle @change="atualizaPermissao('update_users', $event)" :checked="$_.get(user.permissions,'update_users')" color="primary"></custom-toggle>
                        </ion-item>

                        <ion-item>
                            <ion-label class="ion-text-wrap">Visualizar usuários</ion-label>
                            <custom-toggle @change="atualizaPermissao('view_users', $event)" :checked="$_.get(user.permissions,'view_users')" color="primary"></custom-toggle>
                        </ion-item>
                    </ion-item-group>
                </ion-list>

                <br>

                <ion-list>
                    <ion-list-header>
                        <ion-label>Dados Complementares</ion-label>
                    </ion-list-header>

                    <ion-item>
                        <ion-label position="stacked">Criado em (somente leitura)</ion-label>
                        <ion-input v-model="created_at" disabled></ion-input>
                    </ion-item>

                    <ion-item>
                        <ion-label position="stacked">Atualizado em (somente leitura)</ion-label>
                        <ion-input v-model="updated_at" disabled></ion-input>
                    </ion-item>
                </ion-list>

                <br>
                <template v-if="$store.state.usuario.permissions['edit_users'] || $store.state.usuario.permissions['create_users']">
                    <ion-button v-if="salvando" color="primary" expand="full" disabled>
                        <ion-spinner name="crescent"></ion-spinner>
                    </ion-button>
                    <ion-button v-else color="primary" expand="full" @click="save">
                        Salvar&nbsp;<ion-icon name="save-outline"></ion-icon>
                    </ion-button>
                </template>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
//Aux
import {
    reactiveSet,
    toast
} from "@/helpers";

const sha1 = require('sha1');

//Firebase
import {Timestamp} from '@/firebase';

//Ícones Ionic
import {
    addOutline,
    saveOutline,
    chevronBackOutline,
    trashBinOutline,
} from "ionicons/icons";
import {addIcons} from "ionicons";

addIcons({
    "add-outline": addOutline,
    "save-outline": saveOutline,
    "chevron-back-outline": chevronBackOutline,
    "trash-bin-outline": trashBinOutline,
});

//Componentes Ionic
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonIcon,
    IonButton,
    IonListHeader,
    IonInput,
    IonBackButton,
    IonSpinner,
    IonItemDivider,
    IonItemGroup,
    IonSelectOption
} from '@ionic/vue';

//Componentes personalizados
import CustomSelect from '@/components/CustomSelect';
import ValidationError from '@/components/ValidationError';
import CustomToggle from "@/components/CustomToggle";

//Firebase
import {db} from "@/firebase";

//Mixins
import TimestampsMixin from "@/mixins/TimestampsMixin";
import TitleMixin from "@/mixins/TitleMixin";
import ComeBackMixin from "@/mixins/ComeBackMixin";

//Validação
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
    name: "QuestionarioEdit",

    mixins: [
        TimestampsMixin,
        TitleMixin,
        ComeBackMixin
    ],

    //fixme
    validations() {
        let validations = {
            user: {
                name: {
                    required
                },

                email: {
                    required,
                },

                status: {
                    required,
                },

                source: {
                    required,
                }
            }
        };

        if (this.changePassword) {
            validations.newPassword = {
                required
            };

        } else {
            validations.user.password = {
                required
            };
        }

        return validations;
    },

    data() {
        return {
            // fix: 0,
            operation: undefined,
            mainVarName: `user`,
            salvando: false,
            carregandoUsuario: false,

            changePassword: false,
            newPassword: undefined,

            user: {
                code: null,
                name: undefined,
                status: 'active',
                source: 'Pardinho',
                password: undefined,
                permissions: {},
                created_at: undefined,
                updated_at: undefined,
            },
        }
    },

    watch: {
        user: {
            handler(newValue) {
                if (newValue && Object.keys(newValue).length === 0 && newValue.constructor === Object)
                    this.v$.user.$touch();
            },
            immediate: true,
            deep: true
        },
    },

    methods: {
        atualizaPermissao(name, estado) {
            console.log(estado)
            reactiveSet(this.user, `permissions[${name}]`, !estado);
            // this.fix++;
        },

        async save() {
            if (!this.v$.$invalid) {
                const vm = this;
                const now = Timestamp.fromDate(new Date());

                vm.user.updated_at = now;

                //Criar
                if (vm.operation === 'create') {
                    try {
                        vm.user.created_at = now;
                        vm.user.password = sha1(vm.user.password);

                        await db.collection('users')
                            .add(vm.user);

                        toast("Usuário criado com sucesso!");
                        this.comeBack();

                    } catch (err) {
                        toast("Erro ao tentar criar usuário: " + err);
                    }

                    //Editar
                } else {
                    try {
                        if (vm.changePassword)
                            vm.user.password = sha1(vm.newPassword);

                        await db.collection('users')
                            .doc(vm.$route.params.id)
                            .update(vm.user);

                        toast("Usuário atualizado com sucesso!");

                    } catch (err) {
                        toast("Erro ao tentar editar usuário: " + err);
                    }
                }

            } else {
                toast("Dados inválidos. Verifique os campos novamente!");
            }
        }
    },

    created() {
        const vm = this;
        vm.operation = vm.$route.name === 'usuarios.create' ? 'create' : 'edit';

        if (vm.operation === "edit") {
            vm.carregandoUsuario = true;

            vm.$bind('user', db.collection('users').doc(vm.$route.params.id))
                .then(function () {
                    //
                })
                .catch(function (err) {
                    toast(err);
                })
                .then(function () {
                    vm.carregandoUsuario = false;
                });

        }
    },

    setup: () => ({v$: useVuelidate()}),

    components: {
        IonButtons,
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonList,
        IonItem,
        IonLabel,
        IonIcon,
        IonButton,
        IonListHeader,
        IonInput,
        IonBackButton,
        IonSpinner,
        IonItemDivider,
        IonItemGroup,
        IonSelectOption,
        CustomSelect,
        CustomToggle,
        ValidationError
    }
}
</script>

<style scoped>

</style>
