<template>
	<ion-toggle
        @ionChange="changed($event.target)"
		:checked="checked"
	></ion-toggle>
</template>

<script>
	import {IonToggle} from "@ionic/vue";

    export default {
		name: "CustomToggle",

		model: {
			prop: 'status',
			event: 'change'
		},

		data() {
			return {
				checked: false
			}
		},

        methods: {
            changed(target) {
                this.$emit('change', target.getAttribute("aria-checked") === 'true');
            }
        },

		mounted() {
			this.checked = this.status;
		},

		props: {
			status: [Boolean]
		},

        components: {
            IonToggle
        }
	}
</script>

<style scoped>

</style>
